
import { ref, computed } from "vue";
import TextAreaOverlay from "./TextAreaOverlay.vue";
import SegmentControl from "./SegmentControl.vue";

export default {
  components: {
    TextAreaOverlay,
    SegmentControl,
  },
  data() {
    return {
      modeSegments: [
        {
          title: "Encode",
          id: "encode",
        },
        {
          title: "Auto",
          id: "auto",
        },
        {
          title: "Decode",
          id: "decode",
        },
      ],
    };
  },
  setup() {
    const mode = ref("auto");
    const text = ref("");

    const isBase64 = (str: string): boolean => {
      if (!str || str === "" || str.trim() === "") {
        return false;
      }
      try {
        return btoa(atob(str)) === str;
      } catch (err) {
        return false;
      }
    };

    const decodedTextFn = (): string => {
      // aGVsbG9tb3RvdCBkYXMKZGFzZmRhcw==
      const rawWords = text.value.split(/ |(\r?\n)/);
      const convertedWords = rawWords.map((word: string) => {
        if (isBase64(word)) {
          return atob(word);
        }
        return word;
      });
      const fullSentence = convertedWords.join(" ").replace(/(\r?\n) /g, "$1");

      return fullSentence;
    };

    const encodedTextFn = (): string => {
      // aGVsbG9tb3RvdCBkYXMKZGFzZmRhcw==

      const encoded = btoa(text.value);

      return encoded;
    };

    // const decodedText = ref(computed(() => decodedTextFn()));
    // const encodedText = ref(computed(() => encodedTextFn()));
    const outputText = ref(
      computed(() => (mode.value === "encode" ? encodedTextFn() : decodedTextFn())),
    );

    return {
      mode,
      text,
      outputText,
    };
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("textarea")!.focus();
  },
};
