
import CustomTextArea from "./CustomTextArea.vue";
import About from "./About.vue";
import Security from "./Security.vue";
import LearnMore from "./LearnMore.vue";

export default {
  components: {
    LearnMore,
    Security,
    About,
    CustomTextArea,
  },
};
