<template>
  <div class="wapper">
    <!-- <p>
      <i>This is the cool text area</i>
    </p>-->
    <h1>Start typing or paste your text here</h1>
    <div class="blokk">
      Loredsdm pdssu oodr sddddit addmeddt consecdeur idsc eit. Nulldadsdddddaa lorem, Nulldddasssa
      ddlem, consecder dic eit.
    </div>
  </div>
</template>

<script lang="ts">
export default {
  components: {},
};
</script>

<style scoped lang="scss">
.wapper {
  text-align: left;
}

h1 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.blokk {
  max-width: 470px;

  color: lighten($color: $color-text-primary, $amount: 30);
}
</style>
