<template>
  <div>
    <Main />
  </div>
</template>

<script lang="ts">
import Main from "../Main.vue";

export default {
  components: {
    Main,
  },
};
</script>

<style scoped lang="scss">
</style>
